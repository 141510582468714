import { useAuthStore } from '@/store/auth'
import { createRouter, createWebHistory, RouteRecordRaw, useRoute } from 'vue-router'
import RouterIndex from './RouterIndex'
type Tb = {
  name: string | string[]
  url?: string
}
declare module 'vue-router' {
  interface RouteMeta {
    breadcrumb?: Array<Tb>
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/LoginUser.vue'),
  },
  {
    path: '/',
    component: () => import('@/components/layout.vue'),
    redirect: {
      name: 'group',
    },
    children: [
      {
        name: 'group',
        path: 'group',
        component: RouterIndex,
        redirect: {
          name: 'group-index',
        },
        children: [
          {
            name: 'group-index',
            path: 'index',
            meta: {
              breadcrumb: [{ name: '集团管理' }],
            },
            component: () => import('@/pages/group/index'),
          },
          {
            name: 'group-add',
            path: 'add',
            meta: {
              breadcrumb: [{ name: '集团管理', url: '/group' }, { name: ['新建集团', '更新集团'] }],
            },
            component: () => import('@/pages/group/add'),
          },
          {
            name: 'group-detail',
            path: 'detail/:id',
            meta: {
              breadcrumb: [{ name: '集团管理', url: '/group' }, { name: '集团详情' }],
            },
            component: () => import('@/pages/group/detail'),
          },
        ],
      },
      {
        name: 'customer',
        path: 'customer',
        component: RouterIndex,
        redirect: {
          name: 'customer-index',
        },
        children: [
          {
            name: 'customer-index',
            path: 'index',
            meta: {
              breadcrumb: [{ name: '客户管理' }],
            },
            component: () => import('@/pages/customer/index'),
          },
          {
            name: 'customer-add',
            path: 'add',
            meta: {
              breadcrumb: [
                { name: '客户管理', url: '/customer' },
                { name: ['新建客户', '更新客户'] },
              ],
            },
            component: () => import('@/pages/customer/add'),
          },
          {
            name: 'customer-detail',
            path: 'detail/:id',
            meta: {
              breadcrumb: [{ name: '客户管理', url: '/customer' }, { name: '客户详情' }],
            },
            component: () => import('@/pages/customer/detail'),
          },
        ],
      },
      {
        name: 'user',
        path: 'user',
        component: RouterIndex,
        redirect: {
          name: 'user-index',
        },
        children: [
          {
            name: 'user-index',
            path: 'index',
            meta: {
              breadcrumb: [{ name: '用户管理' }],
            },
            component: () => import('@/pages/user/index'),
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/notfind/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// router.beforeEach((to, from, next) => {
//   // TODO ts写法研究
//   const auth = useAuthStore()
//   const client = auth.client
//   if (to.path === '/login') {
//     return next()
//   }
//   if (!client && from.name !== 'login') {
//     // 未登录，没获取用户信息
//     console.log('未登录，返回登录页')
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
