import { defineComponent } from 'vue'
import { RouterView } from 'vue-router'
import className from './index.module.less'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRoute()
    return () => {
      return (
        <div class={className['wrap']}>
          <div class={className['header-tab']}>
            <a-breadcrumb>
              {router.meta.breadcrumb?.map((i) => {
                return i.url ? (
                  <a-breadcrumb-item>
                    <a href={i.url}>{i.name}</a>
                  </a-breadcrumb-item>
                ) : (
                  <a-breadcrumb-item>
                    {Array.isArray(i.name)
                      ? location.href.includes('id')
                        ? i.name[1]
                        : i.name[0]
                      : i.name}
                  </a-breadcrumb-item>
                )
              })}
            </a-breadcrumb>
          </div>
          <RouterView></RouterView>
        </div>
      )
    }
  },
})
